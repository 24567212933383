import "select2";
import "select2/dist/css/select2.css";
import Sortable from "sortablejs";
import "../../sass/vendor/_select2.scss";

$("select:not([data-raw]):not([data-clearable])").select2();

$("select[data-clearable]").select2({
    allowClear: true,
    placeholder: "Choisir une option",
});

const createSortable = (key, select) => {
    const options = $(".select2-selection__rendered", select)[0];
    if (!options) {
        return;
    }

    const reorderItems = (item) => {
        $(item)
            .parent()
            .children("[title]")
            .each(function () {
                const title = $(this).attr("title");
                const option = $(`option[value="${title}"]`, original).first();
                option.detach();
                original.append(option);
            });

        original.trigger("change");
    };

    const original = $(select).siblings("select");
    $(original).on("select2:select", function (event) {
        const attributes = event.params.data;
        const select2Option = $(`li[title="${attributes.text}"]`, select).first();
        const parent = select2Option.parent();

        // Move Select 2 option to the end and reorder
        select2Option.detach();
        parent.append(select2Option);
        reorderItems(select2Option);
    });

    Sortable.create(options, {
        onEnd: (event) => reorderItems(event.item),
    });
};

// Make some options sortable
$("select[data-sortable] + .select2").each(createSortable);
