// Research products when changing filters
$(".layout-search input, .layout-search select").change(function onFilterChange() {
    $(".layout-search input[type=checkbox]").each((key, input) => {
        if ($(input).prop("references")) {
            $(input).parent().prev().attr("disabled", true);
        }
    });

    $(this).closest("form").submit();
});

$("[data-submit]").click(function () {
    $(this).closest("form").attr("action", $(this).data("submit")).submit();
});

$("#select-all").change(function () {
    $("table tbody :checkbox").prop("checked", this.checked);
});
