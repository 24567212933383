import "tablesorter/dist/js/jquery.tablesorter";

$(".table:not(.table-filtered)").tablesorter();

$(".table-filtered").tablesorter({
    widgets: ["filter"],
    widgetOptions: {
        filter_cssFilter: "form-control input-sm",
    },
});
